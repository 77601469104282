import { Navbar, Container } from "react-bootstrap";

import logoBetrack from "../../assets/images/beetrack.png";


export default function Menu(props) {

  let logo = props.logo;

  return (
    <>
      <Navbar className="navbar " bg="dark" expand="lg">
        <Container>

          <Navbar.Brand>
            <div className="row">



              {
                (logo === "beetrack") ? (
                  <div className="col">
                    <img alt="Beetrack" src={logoBetrack} style={{ height: "35px" }} />
                  </div>
                ) : (
                  <div className="col">

                  </div>
                )
              }


              <div className="col">
                <h5 style={{ color: "white" }}>  {props.titulo} </h5>
              </div>


            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </>
  );
}

import { Navbar, Container } from "react-bootstrap";

export default function SubMenu(props) {
  return (
    <>
      <Navbar className="navbar  py-md-0" bg="success">
        <Container>
          <Navbar className="navbar-sm py-0 ">
            <h6 style={{ color: "white" }}> {props.titulo} {props.ordenFlete} </h6>
          </Navbar>
        </Container>
      </Navbar>
    </>
  );
}

let poderAncestral = window.location.ancestorOrigins[0];

let autorizar: boolean = false;

if (window.location.host.toLocaleLowerCase().includes("localhost")) {
  autorizar = true;
} else if (poderAncestral !== undefined ) {
  if(window.location.ancestorOrigins[0].toLocaleLowerCase().includes("starken.topdesk.net")){
    autorizar = true;
  }
}



var referrer = document.referrer;
console.log("referrer url",referrer);




// PLANITRACK API
const PLANITRAK: string = "" + process.env.REACT_APP_PLANITRAK;





// ESTADOS
const EXTRAVIADO: number = +process.env.REACT_APP_EXTRAVIADO;
const PENDIENTE_DESPACHO: number = +process.env.REACT_APP_PENDIENTE_DESPACHO;
const NOMINADO: number = +process.env.REACT_APP_NOMINADO;
const EN_TRANSITO: number = +process.env.REACT_APP_EN_TRANSITO;
const PENDIENTE_ENTREGA: number = +process.env.REACT_APP_PENDIENTE_ENTREGA;
const EN_REPARTO: number = +process.env.REACT_APP_EN_REPARTO;
const PENDIENTE_ENTREGA_EX: number = +process.env.REACT_APP_PENDIENTE_ENTREGA_EX;

const ANULADO: number = +process.env.REACT_APP_ANULADO;
const CERRADO_EXCEPCION: number = +process.env.REACT_APP_CERRADO_EXCEPCION;
const DECOMISADO: number = +process.env.REACT_APP_DECOMISADO;
const DEVUELTO: number = +process.env.REACT_APP_DEVUELTO;
const ENTREGADO: number = +process.env.REACT_APP_ENTREGADO;
const REDESTINADO: number = +process.env.REACT_APP_REDESTINADO;
const REMATADO: number = +process.env.REACT_APP_REMATADO;

export const inicio = {
  permitido: autorizar,
}


export const ESTADOS = {
  EXTRAVIADO: EXTRAVIADO,
  PENDIENTE_DESPACHO: PENDIENTE_DESPACHO,
  NOMINADO: NOMINADO,
  EN_TRANSITO: EN_TRANSITO,
  PENDIENTE_ENTREGA: PENDIENTE_ENTREGA,
  EN_REPARTO: EN_REPARTO,
  PENDIENTE_ENTREGA_EX: PENDIENTE_ENTREGA_EX,
  ANULADO: ANULADO,
  CERRADO_EXCEPCION: CERRADO_EXCEPCION,
  DECOMISADO: DECOMISADO,
  DEVUELTO: DEVUELTO,
  ENTREGADO: ENTREGADO,
  REDESTINADO: REDESTINADO,
  REMATADO: REMATADO
}

const CENTRO_DISTRIBUCION: number = +process.env.REACT_APP_CENTRO_DISTRIBUCION;

export const C_D = {
  CENTRO_DISTRIBUCION: CENTRO_DISTRIBUCION
}

export const MICROSERVICIOS = {

  PLANITRAK:
    PLANITRAK,
}









let TOKEN_PLANITRAK_1: string = "" + process.env.REACT_APP_PLANITRAK_TKN;
let TOKEN_PLANITRAK_2: string = "" + process.env.REACT_APP_PLANITRAK_TKN2;
let TOKEN_PLANITRAK_3: string = "" + process.env.REACT_APP_PLANITRAK_TKN3;

export const PLANITRAK_TKN = {
  token: TOKEN_PLANITRAK_1,
  token2: TOKEN_PLANITRAK_2,
  token3: TOKEN_PLANITRAK_3
}



let TIME_OUT: number = +process.env.REACT_APP_REQUEST_TIMEOUT;

export const REQUEST_TIMEOUT = TIME_OUT; // segundos

let CANT_OBS: number = +process.env.REACT_APP_NUMERO_OBS;

export const NUMERO_OBS = CANT_OBS;


let CANT_TOTAL: number = +process.env.REACT_APP_CANT_TOTAL;

export const CANTIDAD_TOTAL = CANT_TOTAL;

let DS_POR_VENCER: number = +process.env.REACT_APP_DIAS_POR_VENCER;

let DS_VENCIDOS: number = +process.env.REACT_APP_DIAS_VENCIDOS;

let DS_DENTRO_PLAZO: number = +process.env.REACT_APP_DIAS_DENTRO_PLAZO;

export const DIAS_DENTRO_PLAZO = DS_DENTRO_PLAZO;
export const DIAS_POR_VENCER = DS_POR_VENCER;
export const DIAS_VENCIDOS = DS_VENCIDOS;


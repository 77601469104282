import axios from "axios";
import { REQUEST_TIMEOUT, PLANITRAK_TKN } from "./constants";
import { RequestResponse } from "./requestResponse";


export class Request {

  private endpoint: string;
  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  isNetworkError = (err: any) => {
    return !!err.isAxiosError && !err.response;
  };


  async _getHeaderPlanitrack() {
    let header: any = { timeout: 1000 * REQUEST_TIMEOUT };
    let token = "";
    token = PLANITRAK_TKN.token2;



    header = {
      headers: {
        'Access-Controll-Allow-Origin': '*',
        'x-api-key': `${token}`,
        'Access-Control-Request-Headers': 'application/json'
      },
      timeout: 1000 * REQUEST_TIMEOUT + 1000,
    };
    return header;
  }

  async _getHeaderPlanitrack2(key: number) {
    let header: any = { timeout: 1000 * REQUEST_TIMEOUT };
    let token = "";

    if (key === 1) {
      token = PLANITRAK_TKN.token;

    }
    else {
      token = PLANITRAK_TKN.token;
    }

    header = {
      headers: {
        'Access-Controll-Allow-Origin': '*',
        'x-api-key': `${token}`,
        'Access-Control-Request-Headers': 'application/json'
      },
      timeout: 1000 * REQUEST_TIMEOUT + 1000,
    };
    return header;
  }

  // GET PLANITRAK
  async getPlanitrak(url: string): Promise<RequestResponse> {
    const header = await this._getHeaderPlanitrack();

    let url2: string = this.endpoint + url;

    return new Promise(async (resolve) => {
      setTimeout(() => {
        resolve({
          status: -1,
          description: "Supero el tiempo de espera",
          error: "Tiempo de espera superado",
        });
      }, 1000 * REQUEST_TIMEOUT);
      try {
        const response = await axios.get(url2, header);

        resolve({
          status: response.status,
          description: response.statusText,
          data: response.data,
        });
      } catch (error: any) {
        if (this.isNetworkError(error)) {
          resolve({
            status: -1,
            description:
              "No fue posible obtener información, por favor reintente más tarde",
            data: null,
            error:
              "No fue posible obtener información, por favor reintente más tarde",
          });
        } else {
          resolve({
            status: error.response.status,
            description: error.response.data.error,
            data: error.response.data,
            error: error.response,
          });
        }
      }
    });
  }


  // GET PLANITRAK 2
  async getPlanitrak2(url: string, key: number): Promise<RequestResponse> {
    const header = await this._getHeaderPlanitrack2(key);

    let url2: string = this.endpoint + url;

    return new Promise(async (resolve) => {
      setTimeout(() => {
        resolve({
          status: -1,
          description: "Supero el tiempo de espera",
          error: "Tiempo de espera superado",
        });
      }, 1000 * REQUEST_TIMEOUT);
      try {
        const response = await axios.get(url2, header);

        resolve({
          status: response.status,
          description: response.statusText,
          data: response.data,
        });
      } catch (error: any) {
        if (this.isNetworkError(error)) {
          resolve({
            status: -1,
            description:
              "No fue posible obtener información, por favor reintente más tarde",
            data: null,
            error:
              "No fue posible obtener información, por favor reintente más tarde",
          });
        } else {
          resolve({
            status: error.response.status,
            description: error.response.data.error,
            data: error.response.data,
            error: error.response,
          });
        }
      }
    });
  }

}

import React, { useState, useEffect } from "react";
import { inicio } from "../../api/constants/constants";

import {
  Button, Table, Alert, Tooltip, OverlayTrigger, Spinner, Modal
} from "react-bootstrap";

import Menu from "../../containers/menu/menu";

import SubMenu from "../../containers/subMenu/subMenu";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faAngleUp } from "@fortawesome/free-solid-svg-icons";

//MICROSERVICIOS

import { respuestaPlanitrackDto, ofDto, DatosProcesoDto } from "../../api/dto/planitrack/respuestaGetPlanitrack.dto";

import HUBPlanitrack from "../../api/microservices/Planitrack/HUBPlanitack";

// SWEET ALERT 2
import Swal from "sweetalert2";

export default function PaginaPlanitrack(props:any) {

  const { history } = props;

  function validacion() {
    if (inicio.permitido === false) {
      PaginaError();
    }
  }

  function PaginaError() {
    history.push("/InValidDomain");
  }

  useEffect(() => {
    validacion();
    // eslint-disable-next-line
  }, [])

  //=====================================================================================




  type FormElement = React.FormEvent<HTMLFormElement>;

  const [mostrar, setMostrar] = useState(false);

  // seleccion de vistas a mostrar
  // eslint-disable-next-line
  const [estadoRetiro, setEstadoRetiro] = useState(false);
  // eslint-disable-next-line
  const [estadoEntrega, setEstadoEntrega] = useState(false);
  // eslint-disable-next-line
  const [estadoRetiroLi, setEstadoRetiroLi] = useState(false);
  // eslint-disable-next-line
  const [estadoEnvioRetiroLi, setEstadoEnvioRetiroLi] = useState(false);

  // FRONT
  const [nominaFront, setNominaFront] = useState(false);
  const [detalleFront, setDetalleFront] = useState(false);

  // Datos Extra para Front
  const [numeroNomina, setNumeroNomina] = useState(0);

  // let mostrarDet: boolean = true;

  // variable que se maneja el estado central.
  // eslint-disable-next-line
  const [estado, setEstado] = useState(0);  

  // spinner
  // eslint-disable-next-line
  const [load, setLoad] = React.useState(true);
  const [show0, setShow0] = useState(false);
  // eslint-disable-next-line
  const [codigoOrdenFlete, setCodigoOrdenFlete] = useState(0);
// eslint-disable-next-line
  const handleSubmit = (e: FormElement) => {
    e.preventDefault();
  };
// eslint-disable-next-line
  const [ofDto, setOfDto] = useState(
    {} as ofDto
  );
// eslint-disable-next-line
  const [datosProcesoDto, setDatosProcesoDto] = useState(
    {} as DatosProcesoDto
  );

  const [respDatos, setRespDatos] = useState(
    {} as respuestaPlanitrackDto
  );

  const L_solicitud = localStorage.getItem("solicitud");
// eslint-disable-next-line
  const [solicitud, setSolicitud] = useState(
    L_solicitud === null ? [] : JSON.parse(L_solicitud)
  );

  const [lista, setLista] = useState();

  function traeDatos() {
    setShow0(true);

    HUBPlanitrack.getDatos(solicitud).then(async (resp) => {
      // Planitrack.getDatos(solicitud).then((resp) => {  //llama al metodo que hace la consulta y espera un resultado 
      if (resp.error) {
        setShow0(false);
        Swal.fire({
          title: "Hay un Error",
          text: "Ha fallado la cosulta a la DB",
          icon: "error",
          confirmButtonText: "Cerrar",
        });
      } else {
        setShow0(false);
        llenaDatos(resp);
      }
    })
  }

  function llenaDatos(resp: any) {

    let datos = resp.data;

    respDatos.AliasDir = (datos.AliasDir === null) ? "" : datos.AliasDir;
    respDatos.Area_Level_1 = (datos.Area_Level_1 === null) ? "" : datos.Area_Level_1;
    respDatos.Area_Level_3 = (datos.Area_Level_3 === null) ? "" : datos.Area_Level_3;
    respDatos.Calle = (datos.Calle === null) ? "" : datos.Calle;
    respDatos.CantidadOrdenes = (datos.CantidadOrdenes === null) ? 0 : datos.CantidadOrdenes;
    respDatos.CdgExterno = (datos.CdgExterno === null) ? 0 : datos.CdgExterno;
    respDatos.ClienteId = (datos.ClienteId === null) ? 0 : datos.ClienteId;
    respDatos.Contacto = (datos.Contacto === null) ? "" : datos.Contacto;
    respDatos.Destinatario = (datos.Destinatario === null) ? "" : datos.Destinatario;
    respDatos.DetalleProgramacionId = (datos.DetalleProgramacionId === null) ? 0 : datos.DetalleProgramacionId;
    respDatos.Dias = (datos.Dias === null) ? "" : datos.Dias;
    respDatos.DirNormalizada = (datos.DirNormalizada === null) ? 0 : datos.DirNormalizada;
    respDatos.Estado = (datos.Estado === null) ? 0 : datos.Estado;
    respDatos.EstadoGlosa = (datos.EstadoGlosa === null) ? "" : datos.EstadoGlosa;
    respDatos.FolioExterno = (datos.FolioExterno === null) ? 0 : datos.FolioExterno;
    respDatos.Identificador = (datos.Identificador === null) ? "" : datos.Identificador;
    respDatos.Instrucciones_Especiales = (datos.Instrucciones_Especiales === null) ? "" : datos.Instrucciones_Especiales;
    respDatos.IsNormalizada = datos.IsNormalizada;
    respDatos.MovilAsignado = (datos.MovilAsignado === null) ? "" : datos.MovilAsignado;
    respDatos.Numero = (datos.Numero === null) ? 0 : datos.Numero;
    respDatos.NumeroCtaCte = (datos.NumeroCtaCte === null) ? "" : datos.NumeroCtaCte;
    respDatos.Posicion = (datos.Posicion === null) ? "" : datos.Posicion;
    respDatos.Prioridad = (datos.Prioridad === null) ? 0 : datos.Prioridad;
    respDatos.ProgramacionId = (datos.ProgramacionId === null) ? 0 : datos.ProgramacionId;
    respDatos.PuntoId = (datos.PuntoId === null) ? 0 : datos.PuntoId;
    respDatos.Razon_Social = (datos.Razon_Social === null) ? "" : datos.Razon_Social;
    respDatos.Resultado = (datos.Resultado === null) ? 0 : datos.Resultado;
    respDatos.ResultadoGlosa = (datos.ResultadoGlosa === null) ? "" : datos.ResultadoGlosa;
    respDatos.RstCantidad = (datos.RstCantidad === null) ? 0 : datos.RstCantidad;
    respDatos.RstDatos = (datos.RstDatos === null) ? "" : datos.RstDatos;
    respDatos.RstMtvo = (datos.RstMtvo === null) ? "" : datos.RstMtvo;
    respDatos.Rut = (datos.Rut === null) ? "" : datos.Rut;
    respDatos.RutaId = (datos.RutaId === null) ? 0 : datos.RutaId;
    respDatos.Solicitado = (datos.Solicitado === null) ? "" : datos.Solicitado;
    respDatos.TipoProceso = (datos.TipoProceso === null) ? 0 : datos.TipoProceso;
    respDatos.TipoProcesoGlosa = (datos.TipoProcesoGlosa === null) ? "" : datos.TipoProcesoGlosa;
    respDatos.fecha = (datos.fecha === null) ? "" : datos.fecha;
    respDatos.hora_fin = (datos.hora_fin === null) ? "" : datos.hora_fin;
    respDatos.hora_inicio = (datos.hora_inicio === null) ? "" : datos.hora_inicio;
    respDatos.punto_estado = datos.punto_estado;
    respDatos.rsttimestamp = (datos.rsttimestamp === null) ? "" : datos.rsttimestamp;
    respDatos.rsttimestampcheckIn = (datos.rsttimestampcheckIn === null) ? "" : datos.rsttimestampcheckIn;
    respDatos.timestamp_creacion = (datos.timestamp_creacion === null) ? "" : datos.timestamp_creacion;
    respDatos.timestamp_programado = (datos.timestamp_programado === null) ? "" : datos.timestamp_programado;
    respDatos.usuario_mail = (datos.usuario_mail === null) ? "" : datos.usuario_mail;
    respDatos.vehiculo = (datos.vehiculo === null) ? "" : datos.vehiculo;
    respDatos.DataExtra = (datos.DataExtra === null) ? [] : datos.DataExtra;
    respDatos.DatosProceso = (datos.DatosProceso === null) ? [] : datos.DatosProceso;
    respDatos.MtvoDsc = (datos.MtvoDsc === null) ? "" : datos.MtvoDsc;

    // respDatos.FechaCorrecta               = respDatos.timestamp_programado.slice(0,-6) + " " + respDatos.hora_inicio + " a " + respDatos.hora_fin; 
    respDatos.FechaCorrecta = respDatos.fecha + " " + respDatos.hora_inicio + " a " + respDatos.hora_fin;

    setRespDatos({ ...respDatos });

    cambioDeEstado();


    setEstado(respDatos.TipoProceso); // llenar variable para filtrar por estado




    // INICIO MOTIVO EXCEPCION
    const motivoException = respDatos.MtvoDsc;

 

    if (motivoException !== undefined) {
      if (motivoException !== null) {

        if (motivoException === "") {

          //  mostrarDet = false;
          setDetalleFront(false);
        } else {
          // mostrarDet = true;
          setDetalleFront(true);
        }

      }
    }





    // FIN MOTIVO EXCEPCION



    const DataExtra = respDatos.DataExtra;

    if (DataExtra !== undefined) {
      if (DataExtra !== null) {

        const Infor = JSON.stringify(DataExtra[0]);

        if (Infor !== undefined) {
          if (Infor !== null) {

            const Infor2 = JSON.parse(Infor);

            if (Infor2 !== undefined) {
              if (Infor2 !== null) {
                const Datos = JSON.parse(Infor2.Datos);


                // INICIO NOMIN NOMINA

                if (Datos !== undefined) {
                  if (Datos !== null) {
                    const nomina = Datos.nomina;
                    if (nomina !== undefined) {
                      if (nomina !== null) {
                        setNominaFront(true);
                        let validaNomina = JSON.stringify(nomina);
                        if (validaNomina.length !== 0) {
                          setNumeroNomina(nomina.codigo);
                        };
                      }
                    };

                  }
                }
                // FIN NOMINA


                // INICIO ORDEN FLETE
                const listasOrdenesFlete = Datos.listaOrdenesFlete;

                let arrayOf: any[] = []

                if (listasOrdenesFlete !== undefined) {
                  if (listasOrdenesFlete !== null) {

                    const listaOrdenesFlete: any[] = [Datos.listaOrdenesFlete];
// eslint-disable-next-line
                    const recorrido = listaOrdenesFlete.map((of: any) => {

                      let ordenes = of.ordenesFlete;

                      if (ordenes !== undefined && ordenes.length > 1) {
// eslint-disable-next-line
                        const ofs = ordenes.map((det) => {
                          let codigoOf: any = det.codigoOF;
                          arrayOf.push(codigoOf);
                          return null;
                        });

                      } else {
                        let codigoOf: any = of.ordenesFlete.codigoOF;
                        arrayOf.push(codigoOf);
                      }

                      return null;
                    });

                  }
                }


                // INICIO ORDEN FLETE 2

                const ordenFleteSimple = Datos.nroOrdenFlete;

               

                if (ordenFleteSimple !== undefined) {
                  if (ordenFleteSimple !== null) {
                    let codigoOf = ordenFleteSimple;
                    arrayOf.push(codigoOf);
                  }
                }


                let listItems: any = [];

                if (arrayOf.length === 0) {
                  listItems = [
                    <tr>
                      <td>  1 </td>
                      <td> Sin Ordenes de Flete </td>
                    </tr>
                  ]

                  setLista(listItems);
                  setShow0(false);
                } else {

                  listItems = arrayOf.map((of: any, key) => {
                    return (
                      <tr key={of + key}>
                        <td>  {key + 1}   </td>
                        <td>  {of}  </td>
                      </tr>
                    );

                  });
                  setShow0(false);
                  setLista(listItems);
                }




              }
            }





          }
        }












      }
    }















  } // <- Final 



  function cancelar() {
    localStorage.clear();
    history.push("/main/busquedaSolicitud");
  }


  function cambioDeEstado() {

    let estado = respDatos.EstadoGlosa;

    if (estado !== undefined) {
      if (estado !== null) {

        if (estado.trim().toUpperCase() === "PENDIENTE") {
          respDatos.EstadoGlosa = "SIN PROGRAMAR";
        }
        if (estado.trim().toUpperCase() === "ARRIBADO") {
          respDatos.EstadoGlosa = "EN RUTA";
        }
        if (estado.trim().toUpperCase() === "ASIGNADO") {
          respDatos.EstadoGlosa = "EN RUTA";
        }
        if (estado.trim().toUpperCase() === "EN PROGRESO") {
          respDatos.EstadoGlosa = "EN RUTA";
        }
        if (estado.trim().toUpperCase() === "EN TRANSITO") {
          respDatos.EstadoGlosa = "EN RUTA";
        }
        if (estado.trim().toUpperCase() === "EN TRÁNSITO") {
          respDatos.EstadoGlosa = "EN RUTA";
        }
        setRespDatos({ ...respDatos });

      }
    }

  }

  useEffect(() => {
    traeDatos();
    return () => {
      traeDatos();
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Menu titulo="PlaniTrack" />
      <SubMenu titulo="Solicitud Nº :" ordenFlete={solicitud} />



      <Alert className="ml-1" show={mostrar} variant="success">
        <Alert.Heading> Información </Alert.Heading>
        <p> Se Muestran los resultados de la búsqueda </p>

        <div className="d-flex justify-content-end">
          <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="overlay-example">Cerrar Información</Tooltip>}
          >
            <Button
              className="outline-info"
              onClick={() => setMostrar(false)}
              variant="outline-info"
              size="sm"
            >
              <FontAwesomeIcon icon={faAngleUp} />
            </Button>
          </OverlayTrigger>
        </div>
      </Alert>

      {!mostrar && (
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip id="overlay-example">Abrir Información</Tooltip>}
        >
          <Button
            variant="success"
            className="rounded-circle mt-1 pl-1"
            size="sm"
            onClick={() => setMostrar(true)}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </Button>
        </OverlayTrigger>
      )}

      <hr style={{ color: "green" }} />

      <form className="formControl">
        <div className="row d-flex justify-content-center">
          <div className="col-sm-6 mt-3">
            <div
              className="container rounded bt-4 pt-3 pb-2  shadow"
              style={{ background: "white" }}
            >
              <div className="container " style={{ background: "green" }}>
                <h4 style={{ color: "white" }}> Datos Cliente </h4>
              </div>

              <div className="col ml-5 mr-5">
                <div className="row g-3">
                  <label className="col-sm-2 col-form-label">Cliente</label>
                  <div className="col-sm-10">
                    <div className="input-group input-group-sm mb-3">
                      <input id="mensaje"
                        className="form-control"
                        type="text"
                        defaultValue={respDatos.Razon_Social}
                        disabled
                      />
                    </div>
                  </div>

                  <label className="col-sm-2 col-form-label">Nº CtaCte</label>
                  <div className="col-sm-10">
                    <div className="input-group input-group-sm mb-3">
                      <input
                        id="NumeroCtaCte"
                        className="form-control"
                        type="text"
                        defaultValue={respDatos.NumeroCtaCte}
                        disabled
                      />
                    </div>
                  </div>

                  <label className="col-sm-2 col-form-label">Solicitado</label>
                  <div className="col-sm-10">
                    <div className="input-group input-group-sm mb-3">
                      <input
                        id="Solicitado"
                        className="form-control"
                        type="text"
                        defaultValue={respDatos.Solicitado}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 mt-3">

            <div
              className="container rounded bt-4 pt-3 pb-2  shadow"
              style={{ background: "white" }}
            >
              {/* Campo Nomina  */}
              <div hidden={!nominaFront} className="container" style={{ background: "green" }}>
                <h6 style={{ color: "white" }}> Nómina : {numeroNomina}  </h6>
              </div>
              {/* Fin Campo nomina  */}

              <div className="container" style={{ background: "green" }}>
                <h4 style={{ color: "white" }}> Ordenes de Flete </h4>
              </div>


              <div className="container ml-2" style={{ overflow: "scrollx" }}>
                <Table striped bordered-success size="sm">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Numero OF</th>
                    </tr>
                  </thead>

                  <tbody >
                    {lista}
                  </tbody>

                </Table>
              </div>
            </div>
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col-sm-6 mt-5">
            <div
              className="container rounded bt-4 pt-3 pb-2  shadow"
              style={{ background: "white" }}
            >
              <div className="container " style={{ background: "green" }}>
                <h4 style={{ color: "white" }}> Datos Retiro </h4>
              </div>

              <div className="col ml-5 mr-5">
                <div className="row g-3">
                  <label className="col-sm-2 col-form-label">Número de Retiro</label>
                  <div className="col-sm-10">
                    <div className="input-group input-group-sm mb-3">
                      <input
                        id="PuntoId"
                        placeholder="Cargando data ... "
                        className="form-control"
                        defaultValue={respDatos.PuntoId}
                        type="text"
                        disabled
                      />
                    </div>
                  </div>

                  <label className="col-sm-2 col-form-label">Fecha Programada</label>
                  <div className="col-sm-10">
                    <div className="input-group input-group-sm mb-3">
                      <input
                        id="timestamp_programado"
                        placeholder="Cargando data ... "
                        className="form-control"
                        type="text"
                        defaultValue={respDatos.FechaCorrecta}
                        disabled
                      />
                    </div>
                  </div>

                  <label className="col-sm-2 col-form-label">Contacto</label>
                  <div className="col-sm-10">
                    <div className="input-group input-group-sm mb-3">
                      <input
                        id="Contacto"
                        className="form-control"
                        type="text"
                        placeholder="Cargando data ... "
                        defaultValue={respDatos.Contacto}
                        disabled
                      />
                    </div>
                  </div>

                  <label className="col-sm-2 col-form-label">Dirección :</label>
                  <div className="col-sm-10">
                    <div className="input-group input-group-sm mb-3">
                      <input
                        id="DirNormalizada"
                        className="form-control"
                        type="text"
                        placeholder="Cargando data ... "
                        defaultValue={respDatos.DirNormalizada}
                        disabled
                      />
                    </div>
                  </div>


                  <label className="col-sm-2 col-form-label">Estado :</label>
                  <div className="col-sm-10">
                    <div className="input-group input-group-sm mb-3">
                      <input
                        id="ResultadoGlosa"
                        className="form-control"
                        type="text"
                        placeholder="Cargando data ... "
                        defaultValue={respDatos.EstadoGlosa}
                        disabled
                      />
                    </div>
                  </div>

                </div>
              </div>

              <div hidden={!detalleFront}>
                <div className="col ml-5 mr-5">
                  <div className="row g-3 mt-2">
                    <label className="col-sm-2 col-form-label">Detalle :</label>
                    <div className="col-sm-10">
                      <div className="input-group input-group-sm mb-3">
                        <input
                          id="ResultadoGlosa"
                          className="form-control"
                          type="text"
                          placeholder="Cargando data ... "
                          defaultValue={respDatos.MtvoDsc}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="col-sm-6 mt-5">
            <div
              className="container rounded  bt-4 pt-3 pb-2  shadow"
              style={{ background: "white", height: "378px" }}
            >
              <div className="container " style={{ background: "green" }}>
                <h4 style={{ color: "white" }}> Observaciones </h4>
              </div>

              <textarea
                className="form-control"
                id="Instrucciones_Especiales"
                defaultValue={respDatos.Instrucciones_Especiales}
                style={{ height: "280px" }}
              >
                {respDatos.Instrucciones_Especiales}
              </textarea>
            </div>
          </div>
        </div>

        <div>
          <div className="d-flex justify-content-center mt-3">

            <div className="col-sm-1 text-center">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="overlay-example" className="outline-danger">
                    Cerrará lá página
                  </Tooltip>
                }
              >
                <button className=" btn btn-outline-danger shadow"
                  onClick={() => cancelar()}


                >
                  Cerrar
                </button>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </form>

      <hr style={{ color: "green" }} />

   

      <Modal
        show={show0}
        // onHide="true"
        size="sm"
        backdrop="static"
        keyboard={false}
      >

        <Modal.Body>

          <div className="container text-center">
            <Spinner animation="border" role="status" variant="success" size="sm" />
            <Spinner animation="border" role="status" variant="success" />
          </div>
          <div className="container text-center">
            <h6 style={{ color: "green" }}>Cargando...</h6>
          </div>

        </Modal.Body>

      </Modal>

    </>
  );
}

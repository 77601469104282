import React from "react";
import ReactDOM from "react-dom";
import RoutesMain from "./routes/main";

import * as dotenv from "dotenv";
import "./index.css";

import "bootstrap/dist/css/bootstrap.min.css";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";


import "./assets/css/main.css";

import "moment/locale/es";

ReactDOM.render(
  <React.StrictMode>
    <RoutesMain />
  </React.StrictMode>,
  document.getElementById("root")
);

dotenv.config();

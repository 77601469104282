import React, { useEffect } from "react";


import { Button, Container, Col, Row } from "react-bootstrap";


export default function App(props: any) {

  const { history } = props;


  //=====================================================================================

  function inicio() {

    history.push("/main/busquedaSolicitud");
  }


  return (
    <>
      <Container className="rounded mt-5 pb-3 w-40 shadow"
        style={{ background: "white" }}>
          <Row>
        <h2 className="text-center"> Página de Inicio Planitrack </h2>

          </Row>

          <Row>

        <Col className="d-flex justify-content-center">
          <Button className="text-center" variant="success" onClick={inicio}> Continuar </Button>
        </Col>
          </Row>

      </Container>
    </>
  )
}



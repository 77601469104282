import { BrowserRouter, Switch, Route } from "react-router-dom";

import View404                from "../views/404";
import DominioIncorrecto      from "../views/DominioIncorrecto";

// PAGINAS PLANITRACK
import P_BusquedaSoli         from "../views/01_Planitrack/01_busquedaSoli";
import P_paginaPlanitrack     from "../views/01_Planitrack/02_paginaPlanitrack";

// PAGINAS PLANITRACK QA
// import P_BusquedaSoli_QA      from "../views/02_Planitrack_QA/01_busquedaSoli";
// import P_paginaPlanitrack_QA  from "../views/02_Planitrack_QA/02_paginaPlanitrack";


import App                    from "../App";

export default function RoutesMain() {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={App} path="/" exact />
        {/* PLANITACK */}
        <Route
          component={P_BusquedaSoli}
          path="/main/busquedaSolicitud"
          exact
        />
        <Route
          component={P_paginaPlanitrack}
          path="/main/trackingPlanitrack"
          exact
        />

          {/* PLANITACK 2*/}
          {/* <Route
          component={P_BusquedaSoli_QA}
          path="/Planitrack_QA/busquedaSolicitud"
          exact
        />
        <Route
          component={P_paginaPlanitrack_QA}
          path="/Planitrack_QA/trackingPlanitrack"
          exact
        /> */}

        {/* DOMINIO INCORRECTO */}
        <Route component={DominioIncorrecto} path="/InValidDomain" exact />

        {/*  ERROR */}
        <Route component={View404} path="*" exact />

      </Switch>
    </BrowserRouter>
  );
}

import React, { useState, useEffect } from "react";
import { inicio } from "../../api/constants/constants";
import { Button, Alert, Tooltip, OverlayTrigger, Modal, Spinner } from "react-bootstrap";
import Menu from "../../containers/menu/menu";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import HUBPlanitrack from "../../api/microservices/Planitrack/HUBPlanitack";
import Swal from "sweetalert2";

import { consultaPlanitrackDto } from "../../api/dto/planitrack/consultaGetPlanitrack.dto";

type FormElement = React.FormEvent<HTMLFormElement>;

export default function BusquedaSoli(props) { 

  const { history } = props;

  function validacion() {
    if (inicio.permitido === false) {
      PaginaError();
    }
  }

  function PaginaError() {
    history.push("/InValidDomain");
  }

  useEffect(() => {
    validacion();
    // eslint-disable-next-line
  }, [])

  //=====================================================================================

  const [mostrar, setMostrar] = useState(false);

  const [solicitud, setSolicitud] = useState("");

// eslint-disable-next-line
  const [numeroSolicitud, setnumeroSolicitud] = useState(
    {} as consultaPlanitrackDto
  );
  function pasarPagina() {
    history.push("/main/trackingPlanitrack");
  }

  function clear() {
    setSolicitud(" ");
    history.push("/main/busquedaSolicitud");
  }

  const [show0, setShow0] = useState(false);

  const handleSubmit = (e: FormElement) => {
    e.preventDefault(); // que no funcione el boton normal

    setShow0(true);

    HUBPlanitrack.getDatos(solicitud).then(async(result) => {  //llama al metodo que hace la consulta y espera un resultado Metodo de 3 Tokens
    // Planitrack.getDatos(solicitud).then((result) => {  // Metodo de un Solo Token 

      if (result.error) {
        setShow0(false);

        let error: string = "" + result.data.error;

        Swal.fire({
          title: "Error",
          text: "Hay un Problema : " + error,
          icon: "error",
          confirmButtonText: "Cerrar",
        });

      } else {

        let conteo = result.data;
        let largo = conteo.length;

        if (largo === 0) {
          setShow0(false);
          Swal.fire({
            title: "Error",
            text: "Solicitud No existe",
            icon: "error",
            confirmButtonText: "Cerrar",
          });

          clear();
        }
        else {
          setShow0(false);
          Swal.fire({
            title: "Éxito!",
            text: "Solicitud Encontrada",
            icon: "success",
            confirmButtonText: "continuar",
          });

          localStorage.setItem('solicitud', solicitud);
          pasarPagina();
        }
      }
    });
  };


  return (
    <>
      <Menu titulo="PlaniTrack" />

      <Alert className="ml-1" show={mostrar} variant="success">
        <Alert.Heading> Información </Alert.Heading>
        <p>
          Es necesario ingresar una Solicitud  <b>Válida. </b>
        </p>

        <div className="d-flex justify-content-end">
          <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="overlay-example">Cerrar Información</Tooltip>}
          >
            <Button
              className="outline-info"
              onClick={() => setMostrar(false)}
              variant="outline-info"
              size="sm"
            >
              <FontAwesomeIcon icon={faAngleUp} />
            </Button>
          </OverlayTrigger>
        </div>
      </Alert>

      {!mostrar && (
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip id="overlay-example">Abrir Información</Tooltip>}
        >
          <Button
            variant="success"
            className="rounded-circle mt-1 pl-1"
            size="sm"
            onClick={() => setMostrar(true)}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </Button>
        </OverlayTrigger>
      )}

      <hr style={{ color: "green" }} />

      <div
        className="container rounded pt-3 pb-3 w-40 shadow"
        style={{ background: "white" }}
      >
        <div className="text-center">
          <h4> Ingresar Número de Solicitud de retiro </h4>
        </div>

        <form
          className="row d-flex justify-content-center w-80"
          onSubmit={handleSubmit}
        >
          <div className="w-50">
            <input
              className="form-control"
              type="text"
              placeholder="Ej: 12345678"
              onChange={(e) => setSolicitud(e.target.value)}
            />
            <label>Número de Solicitud de retiro</label>
          </div>

          <div className="text-center">
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="overlay-example">
                  Se buscará el número de Solicitud de retiro: {solicitud}
                </Tooltip>
              }
            >
              <button className=" btn btn-success shadow "> Buscar Solicitud de retiro</button>
            </OverlayTrigger>
          </div>
        </form>
      </div>

      <hr style={{ color: "green" }} />



      {/* MODAL LOADING */}

      <Modal
        show={show0}
        // onHide="true"
        size="sm"
        backdrop="static"
        keyboard={false}
      >

        <Modal.Body>

          <div className="container text-center">
            <Spinner animation="border" role="status" variant="success" size="sm" />
            <Spinner animation="border" role="status" variant="success" />
          </div>
          <div className="container text-center">
            <h6 style={{ color: "green" }}>Cargando...</h6>
          </div>

        </Modal.Body>

      </Modal>

    </>
  );
}

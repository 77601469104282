
import React from "react";
import "../assets/css/main.css";

import logo from "../assets/images/logo-starken.png";

export default function DominioInCorrecto(props: any) {

  return (
    <div className="containerPrincipal" style={{ background: "white" }}>
      <div className="containerSecundario ">

        <div style={{ marginBottom: 20 }}>
          <img alt="banner" src={logo} />
        </div>

        <hr style={{ color: "green" }} />


        <div style={{ marginBottom: 20 }}>
          <h3>No se puede ingresar desde este dominio. </h3>
          <h4>Por favor, inicia sesión en TOPdesk. </h4>
          <hr style={{ color: "green" }} />
          <a className="btn btn-success" href="https://starken.topdesk.net/"> TOPdesk </a>
        </div>

      </div>
    </div>
  );
}


import Planitrack from "../Planitrack/Planitrack_1";

export default class HUBPlanitrack {

    static async getDatos(solicitud: string) {

        let Respuesta1 = await Planitrack.getDatos(solicitud, 1).then(async (result) => {

            if (result.status === 200) {
                return result;
            } else {
                let Respuesta2 = await Planitrack.getDatos(solicitud, 2).then(async (result) => {
                    if (result.status === 200) {
                        return result;
                    } else {
                        console.log("llegue 3", solicitud);
                        let Respuesta3 = await Planitrack.getDatos(solicitud, 3).then(async (result) => {
                            return result;
                        })
                        return Respuesta3;
                    }
                });
                return Respuesta2;
            }
        });

        return Respuesta1;

    }

}

